

/* Estilo para enlaces de navegación */
Nav a  {
  padding: 1%;
  margin: 2%;
  display: inline-block;
  width: 20%;
  height: 10%;
  
 
}

@media (min-width: 992px) {
  Nav {
      height: 80px;
            
  }


}

@media (min-width: 2000px) {
  Nav  {
    height: 200px;
  
  }
  a.Nav img{
    
     width:200px 
  }

  .link{
    font-size: 20px;
            
   }
}

@media (min-width: 4000px) {
 

  .link{
    font-size: 40px;
            
   }
}

@media (max-width: 990px) {
  Nav a{
    border-bottom: 1px solid rgb(255, 255, 255)
           
  }
  .me-auto .link, .me-autonavbar-nav, .link {
    text-align: left;
    align-items: normal;
   
  }
 
 
}

@media (min-width: 991px){ 
 .me-auto {
  align-items: center;
 }
}

nav {
  display: flex;
  justify-content: space-between;
}

.link  {
  text-decoration: none;
  color: white;
  padding: 1%;
  margin: 2%;
  font-size: 15px;
  display: inline;  
  justify-content: space-between;
  text-align:center;
  align-items: center; 
  font-weight: bold; 
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif
  
  
}

/* Estilo de hover para enlaces de navegación */
.link:hover {
  color: rgb(228, 221, 221);
  text-decoration: underline;
}

.imgNavSlo{
  display: flex;
  justify-content: start;
  align-content: start;
 
}

.imgSlo{
  width:150px;
 margin-right: 2%;
 align-items: center;
 
 
}

@media (max-width: 645px) {
  .imgSlo{
    margin-left: 25%;
           
  }

}

@media (max-width: 1000px) {
  .imgNavSlo  {
     display: none;
     visibility: hidden;
     
  }

 
}

@media (min-width: 1200px) {
  .imgSlo{
    width:200px;
    
           
  }

}



   