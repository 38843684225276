h1{
    text-align: center;
    color:white;
    text-shadow: 1px 1px 3px #000000;
 
}


.content  {
    
    width: 100%;   
    background-color:rgb(255, 255, 255)
    
  }

  .content h1 {
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    padding-top: 50px;
  }

  #view-first-content{
  
    display: flex;          
    overflow: hidden;
    width: 100%; 
    height: 100vh; 

}

#view-second-content{
   
    display: flex;         
    overflow: hidden;
    width: 100%; 
    height: 100vh; 
}

#view-third-content{
     
  display: flex;
  width: 100%;
  height: 100vh;
  overflow: hidden;

}

#view-third-content p {
  display: none;
  visibility: hidden
}

@media (min-width: 881px) {
  #view-first-content, #view-second-content {
      height: 100vh;
      
  }
}

.contentTitle{
    background-image: url('../Multimedia/Contenido img/IMG_1878.jpeg');
    background-size: cover; 
    height: 40vh;
    width: 100%; 
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center;
    filter: grayscale(100%)
    

}

@media (max-width: 900px) {
    #view-first-content img, #view-second-content img  {
        width: 100%;
    }
  }

  @media (min-width: 1900px) {
    .content h1  {
        font-size: 80px;
    }
  }

  @media (max-width: 960px) {
    #view-first-content, #view-second-content,#view-third-content{
        height: 60vh;
    }

  }

  #conatiner-extra-images {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    margin-top: 5%;
  }
  
  #extra-images1 {
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  #extra-images1 img {
    width: 60%;
    max-width: 70%;
    height: auto;
  }
  
  
  @media (max-width: 800px) {
    #extra-images1 img {
       width: 100%;
       max-width: 100%;
      margin-left:6%;
      margin-right:6%
    }
  
    #conatiner-extra-images{
      margin-left: 6%;
      margin-right: 6%;
    }
  
  }
  

  @media (min-width: 1900px) {
    #extra-images1 img , #extra-images2 img ,#extra-images3 img,#extra-images4 img {
       width: 50%;
      
    }
  }