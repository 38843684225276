.production  {
    
    width: 100%;   
    background-color:rgb(255, 255, 255)
    
  }

  .production h1 {
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    padding-top: 50px;
  }

  @media (min-width: 2000px) {
    .production h1  {
        font-size: 100px;
    }
  }


  #view-first-production{
    
    display: flex;
    width: 100%;  
    height: 100vh;      
    overflow: hidden;
  }

#view-second-production{
    
    display: flex;
    width: 100%;
    height: 100vh;  
    overflow: hidden;
}

.productionTitle{
    background-image: url("../Multimedia/Centros de Producción img/IMG_8088.jpeg");
    background-size: cover; 
    height: 40vh;
    width: 100%; 
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center;
    filter: grayscale(100%)
       

}

@media (max-width: 900px) {
    #view-first-production img, #view-second-production img  {
        width: 100%;
    }

    #view-second-production p {
      display: none;
       
     }
  }



  #view-third-production{
     
    display: flex;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  
  }

  #view-second-production p {
   opacity: 0;
    
  }

  @media (max-width: 960px) {
    #view-first-production{
        height: 60vh;
    }

    #view-second-production{
      height: 40vh;
  }

  }

  @media (min-width: 1900px) {
    .production h1  {
        font-size: 80px;
    }
  }

  #conatiner-extra-images {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    margin-top: 5%;
  }
  
  #extra-images1 {
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  #extra-images1 img {
    width: 60%;
    max-width: 100%;
    height: auto;
  }
  
  
  @media (max-width: 800px) {
    #extra-images1 img {
       width: 100%;
      margin-left:6%;
      margin-right:6%
    }
  
    #conatiner-extra-images{
      margin-left: 6%;
      margin-right: 6%;
    }
  
  }
  
  @media (min-width: 1900px) {
    #extra-images1 img , #extra-images2 img ,#extra-images3 img,#extra-images4 img {
       width: 50%;
      
    }
  }