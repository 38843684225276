.text-and-image-container {
    width: 100%;  
    align-items: center;
    justify-content: center;
    gap: 20px;
    display: flex;
    flex-wrap: wrap;
  
}

#container-image-left {
    width: 100%;            
    height:70%;   
    margin-top: 10%;    
    left: 70px;  
    
}

#container-image-right {
    width: 100%;          
    height:70%;   
    margin-top: 10%;    
    right: 100px;
    
}
  
.allImagesContainer{
    background-color: #000000;       
    height:100%;
    width: 50%;
       
    
}


#container-text {
    
    max-width: 470px;
    color: rgb(86, 84, 84); 
    text-align:left;
    background-color: #e1e0e066;
    padding: 10px;
    border-radius: 10px;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
   ;
 
   
    
           
}

@media (max-width: 900px) {

    .text-and-image-container{
    padding: 0px;
    }
       
    }

  

    @media (max-width: 520px) {
        #container-text  {
            text-align:center; }

            .text-and-image-container{
                margin: 5%;
            }
           
            .allImagesContainer{
               width:100%;
               
             }

                  

    }

    @media (max-width: 620px) {
        
    .allImagesContainer{
        
        max-width: 700px;
      }
    }

    @media (max-width: 890px) {
        

            .allImagesContainer{
               height: auto;
            }

            .text-and-image-container{

                gap: 0%;
                margin-top: 1%;
                
            }
                    

    }

    @media (max-width: 870px) { 
        .allImagesContainer{
        background: none;
        width: 100%;
        margin-left: 2%;
        margin-right: 2%;
    }
    
     

    
    }
    @media (min-width: 880px) {
       

            .text-and-image-container{
                justify-content: space-between;
                
            }

            #container-image-right, #container-image-left{
                position: absolute;
            }

            .allImagesContainer{
                position:relative;
                width:50%
            }

            #container-text{
                margin-left: 2%;
                margin-right: 2%;
                padding: 40px;
          
            }
            

    }

    @media (min-width: 1900px) {
        #container-text  {
          font-size: 20px;
          max-width: 900px;
          margin-left: 5%;
            
    }
   

     


#container-image-left {
   
    left: 450px;  
    
}

#container-image-right {
        
    right: 420px;
    
}

  
}



@media (min-width: 1920px) {
  
#container-text  {
    font-size: 20px; 
    margin-left: 5%;
    margin-right: 2%;
    max-width: 1000px;
    
      
}



#container-image-left {

left: 20%;  

}

#container-image-right {
  
right: 17%;

}



}


