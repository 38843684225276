.custom-layout {
  width: 100%;
}

.footer{
    
   width: 100%;    
   display: flex;    
   align-items: center;
   flex-direction: column;
   flex-wrap: wrap;  
   margin-top: 10%;
     
        
  }

  .footer h2{
    font-size: 12px;
    text-align: left;
    align-self:center;   
    font-family: 'Montserrat', sans-serif;
   
  }

  .img-footer img{
    width:120px;
    align-self: flex-end;
    flex-wrap: wrap;  
    margin-bottom: 5%;
    margin-right: 5px;
       
  }

  .img-footer{
    display: flex;
    align-self:flex-end;  
    flex-wrap: wrap;
  
  }

  .brands img{   
     
      width: 7%;
      height: 10%;
      flex-wrap: wrap;  
      margin: 3%; 
         
  }

  .brands {
   
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-bottom: 3px solid #0c04014b ;
       
  }

  @media (max-width: 370px) {
    .img-footer  img  {
      width:90px;
    }
    .brands img{
      height: 10% ;
      width: 10%;
    }

    .footer h2{
     font-weight: bold;
    }

  }

  @media (max-width: 800px) {
   
    .brands img{
      height: 8% ;
      width: 8%;
    }

  }

  @media (max-width: 760px) {
   
    .brands img{
      height: 7% ;
      width: 8%;
    }

  }

  @media (max-width: 500px) {
   
    .brands img{
      height: 10% ;
      width: 10%;
    }

    .infoFooter{
      flex-direction: column !important;
      justify-content: center !important;
      text-align: center !important;
      max-width: 500vw;
      margin: 0 auto;
      gap: 10px;
      

      
    }

    .infoFooter h2 {
      justify-content: center !important;
      max-width: 100px;
      margin-left: 0%;
      text-align: center;
    }

    .infoFooter img{

      width:15%
      
       
      }    
    
      .year{
        text-align: center;
      }

      .instagram{
        margin-right: 5%;
      }
      .instagram svg{
        margin-right: 5%;
        font-size: 32px;
      }
      .instagram h2{
        margin-left: 5%;
     
      }
    }


  

  @media (min-width: 2000px) {
   

    .footer h2{
     font-weight: bold;
     font-size: 40px;
    }

    .img-footer  img  {
      width:300px;
    }

    .brands img{
      height: 8% ;
      width: 8%;
    }

  }

  @media (min-width: 800px) {
   

    .infoFooter{

    justify-content: space-around;
    align-items: center;
    align-content: center;
    
      
    }

    .footer h2{
      text-align: center;
      font-size: 12px;
    }
  }



  .infoFooter{
    
    align-items: center;      
    color: #9e9998b6;
    width:100vw;
    display: flex;
    justify-content: space-around;
    align-items: center;     
    margin-top: 1%;
    margin-bottom: 1%;
    text-align: left;
     
    
  }

  .infoFooter h2{
    
   font-size: 10px;  
   margin-left: 2%;
    
    
  }
  

  .contact{
      
    display: flex;
    flex-direction: row;
    gap:20px
    
  }

  .instagram{
    
    display: flex;
    flex-direction: row;
    text-align: left;
    margin-top:5px;
    gap:20px
    
  }

  .instagram svg {
    right:2%
  }


  .footerText{
   display: flex;
   flex-direction: row;   
  align-content: center;
  text-align: left;
  
    

  }

   svg{
    
    font-size: 30px;
    }

        
      .rightsReserved{
        color: #9e9998b6;
      }

      