.television  {
    
    width: 100%;   
    background-color:rgb(255, 255, 255)
    
  }

  .television h1 {
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    padding-top: 50px;
  }

  @media (min-width: 2000px) {
    .television h1  {
        font-size: 100px;
    }
  }


  #view-first-television{
  
    display: flex;
    width: 100%;  
    height: 100vh;      
    margin-bottom: 10px;
    overflow: hidden;
   
}

#view-second-television{
     
    display: flex;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  
}

#view-third-television{
     
  display: flex;
  width: 100%;
  height: 100vh;
  overflow: hidden;

}



.televisionTitle{
    background-image: url('../Multimedia/MovilesTv img/IMG_9559.jpeg');
    background-size: cover; 
    height: 40vh;
    width: 100%; 
    display: flex;
    justify-content: center;
    align-items: center;
    background-position:25% 65%;
    filter: grayscale(100%) 
   
    

}

@media (max-width: 900px) {
  .televisionTitle {
    background-position:center;
    }
  }

  @media (max-width: 500px) {
    #view-first-television img, #view-second-television img, #view-third-television img  {
        width: 100%;
    }
  }

 
  #view-third-television p {
    display: none;
    visibility: hidden
  }

  @media (max-width: 960px) {
    #view-first-television, #view-second-television  {
        height: 80vh;
    }

  }

  @media (min-width: 1900px) {
    .television h1  {
        font-size: 80px;
    }
  }

  #conatiner-extra-images {
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-direction: column;
    gap: 30px;
    margin-top: 5%;
  }
  
  #extra-images1t {
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  #extra-images2 {
    display: flex;
    justify-content: center;
    align-items: center;
    
    
  }
  #extra-images1 img {
    width: 60%;
    max-width: 70%;
    height: auto;
  }
  
  #extra-images2 img {
    width: 60%;
    max-width: 70%;
    height: auto;
  }
  
  @media (max-width: 800px) {
    #extra-images1 img,#extra-images2 img {
      width: 100%;
      max-width: 100%;
      margin-left:6%;
      margin-right:6%
    }
  
    #conatiner-extra-images{
      margin-left: 6%;
      margin-right: 6%;
    }
  
  }

  @media (min-width: 1900px) {
    #extra-images1t img , #extra-images2 img ,#extra-images3 img,#extra-images4 img {
       width: 50%;
      
    }
  }