.channels  {
    
    width: 100%;   
    background-color:rgb(255, 255, 255);
  
    
  }

  .channels h1 {
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
      padding-top: 50px;
  }

  @media (min-width: 2000px) {
    .channels h1  {
        font-size: 100px;
    }
  }


  #view-first-channel{
  
    display: flex;
    width: 100%; 
    height: 100vh; 
    overflow: hidden;
}

#view-second-channel{
    
    display: flex;
    width: 100%;
    height: 100vh; 
    overflow: hidden;
}

.channelsTitle{
    background-image: url('../Multimedia/Canales img/72080e9e-dd51-42c2-ba25-24873d3f367c.JPG');
    background-size: cover; 
    height: 40vh;
    width: 100%; 
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center;
    filter: grayscale(100%)
   
    

}


#view-third-channel{
    
  display: flex;
  width: 100%;
  height: 100vh; 
  overflow: hidden;
}


@media (max-width: 900px) {
    #view-first-channel img, #view-second-channel img ,#view-third-channel img {
        width: 100%;
    }
  }

  @media (max-width: 960px) {
    #view-first-channel, #view-second-channel,#view-third-channel {
        height: 60vh;
    }

  }

  @media (min-width: 1900px) {
    .channels h1  {
        font-size: 80px;
    }
  }

  #conatiner-extra-images {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    margin-top: 5%;
  }
  
  #extra-images1 {
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  #extra-images1 img {
    width: 60%;
    max-width: 70%;
    height: auto;
  }
  
  
  @media (max-width: 800px) {
    #extra-images1 img {
       width: 100%;
      margin-left:6%;
      margin-right:6%
    }
  
    #conatiner-extra-images{
      margin-left: 6%;
      margin-right: 6%;
    }
  
  }
  
  @media (min-width: 1900px) {
    #extra-images1 img , #extra-images2 img ,#extra-images3 img,#extra-images4 img {
       width: 50%;
      
    }
  }