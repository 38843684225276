
.home  {
  background-color: white;
  width: 100%;   
}

.video   {
  width: 50vw;   
  margin-top: 5%;
 
}

.container-video{
  display: flex;
  justify-content: center;  
  width:100%;
  background-color: black;
  
 
}

@media (min-width: 2000px) {
   

  .video {
    width: 90vw;
  }

}


@media (max-width: 900px) {
 .video  {
    
    margin-left: 4%;
    margin-right: 4%;
     
  }

  
}


#view-first{
 
  display: flex;  
  overflow: hidden;
 
 
}

#view-two{

  display: flex;
  overflow: hidden;
}
#view-third{

  display: flex;
  overflow: hidden;
 
}
#view-four{

  display: flex;
  overflow: hidden;

  
}
#view-five{

  display: flex;
  overflow: hidden;
 
}

#view-third p,#view-five p {
  display: none;
  visibility: hidden
}


#view-four p{
  opacity: 0;
}

@media (min-width: 881px) {
  #view-first, #view-two,#view-third,#view-four,#view-five {
      height: 100vh;
      
  }
}



@media (max-width: 500px) {
  .container-video {
      height: 45vh;
           
  }
  .video{
    margin-top: 10%;
  }
}

@media (max-width: 900px) {
  #view-first img, #view-two img  {
      width: 100%;
      
  }
  #view-first p {
  margin-top:5%;
    
}
}


@media (max-width: 960px) {
  #view-first, #view-two,#view-third,#view-four,#view-five {
      height: 100%;
    
  }

}



#conatiner-extra-images {
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-direction: column;
  gap: 30px;
  margin-top: 5%;
}

#conatiner-middle-images {
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-direction: column;
  gap: 30px;
  
  
}

#extra-images1 {
  display: flex;
  justify-content: center;
  align-items: center;
  
}
#extra-images2 {
  display: flex;
  justify-content: center;
  align-items: center;
  
}
#extra-images3 {
  display: flex;
  justify-content: center;
  align-items: center;
  
}

#middle {
  display: flex;
  justify-content: center;
  align-items: center;
  
}

#extra-images1 img {
  width: 60%;
  max-width: 100%;
  height: auto;
}

#extra-images2 img {
  width: 60%;
  max-width: 100%;
  height: auto;
}

#extra-images3 img {
  width: 60%;
  max-width: 100%;
  height: auto;
}


#middle img {
  width: 60%;
  max-width: 100%;
  height: auto;
}
@media (max-width: 800px) {
  #extra-images1 img , #extra-images2 img ,#extra-images3 img, #middle img {
     width: 100%;
    margin-left:6%;
    margin-right:6%
  }

  #conatiner-extra-images{
    margin-left: 6%;
    margin-right: 6%;
  }

  #conatiner-middle-images{
    margin-bottom: 5%;
  margin-left: 5%;
  margin-right: 5%;
  }

}

@media (min-width: 1900px) {
  #extra-images1 img , #extra-images2 img ,#extra-images3 img,#extra-images4 img {
     width: 50%;
    
  }
}
