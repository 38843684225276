.rights  {
    
    width: 100%;   
    background-color:rgb(253, 253, 253)
    
  }

  .rights h1 {
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
      padding-top: 50px;
  }

  @media (min-width: 2000px) {
    .rights h1  {
        font-size: 100px;
    }
  }


  #view-first-rights{
  
    display: flex;
    width: 100%;  
    height: 100vh;     
    overflow: hidden;
  }

#view-second-rights{
   
    display: flex;
    width: 100%;
    height: 100vh; 
    overflow: hidden;
}
#view-third-rights{
   
  display: flex;
  width: 100%;
  height: 100vh; 
  overflow: hidden;
}
#view-four-rights{
   
  display: flex;
  width: 100%;
  height: 100vh; 
  overflow: hidden;
}
#view-five-rights{
   
  display: flex;
  width: 100%;
  height: 100vh; 
  overflow: hidden;
}
.rightsTitle{
    background-image: url("../Multimedia/Gestion de Derechos img/wallpapertitlerightss.jpg");
    background-size: cover; 
    height: 40vh;
    width: 100%; 
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center;
    filter: grayscale(100%)
       

}

@media (max-width: 900px) {
    #view-first-rights img, #view-second-rights img  {
        width: 100%;
    }
  }

  @media (max-width: 500px) {
    #view-third-rights p,#view-four-rights p,#view-five-rights p  {
      display: none;
      visibility: hidden
    }
  
  }


  @media (max-width: 960px) {
   
    #view-first-rights{
      border-bottom: 3px grey ;
    }
  }

 
  @media (max-width: 960px) {
    #view-first-rights, #view-second-rights,#view-third-rights,#view-four-rights,#view-five-rights {
        height: 60vh;
    }

  }

  #view-third-rights p,#view-five-rights p  {
    display: none;
    visibility: hidden
  }

  #view-four-rights p{
    opacity: 0;
  }


  @media (min-width: 1900px) {
    .rights h1  {
        font-size: 80px;
    }
  }

#conatiner-extra-images {
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-direction: column;
  gap: 30px;
  margin-top: 5%;
}

#conatiner-extra-images img {
width:50%
}

#extra-images1 {
  display: flex;
  justify-content: center;
  align-items: center;
  
}
#extra-images2 {
  display: flex;
  justify-content: center;
  align-items: center;
  
}
#extra-images3 {
  display: flex;
  justify-content: center;
  align-items: center;
  
}
#extra-images4 {
  display: flex;
  justify-content: center;
  align-items: center;
  
}

#extra-images1 img {
  width: 60%;
  max-width: 70%;
  height: auto;
}

#extra-images2 img {
  width: 60%;
  max-width: 70%;
  height: auto;
}

#extra-images3 img {
  width: 60%;
  max-width: 70%;
  height: auto;
}

#extra-images4 img {
  width: 60%;
  max-width: 70%;
  height: auto;
}

@media (max-width: 800px) {
  #extra-images1 img , #extra-images2 img ,#extra-images3 img,#extra-images4 img {
     width: 100%;
    margin-left:6%;
    max-width: 100%;
    margin-right:6%
  }

  #conatiner-extra-images{
    margin-left: 6%;
    margin-right: 6%;
  }

 

}

@media (min-width: 1900px) {
  #extra-images1 img , #extra-images2 img ,#extra-images3 img,#extra-images4 img {
     width: 50%;
    
  }
}
